<template>
  <div class="master-panel">
    <div class="left-panel">
      <span class="helper"></span>
      <svg width="252" height="100" viewBox="0 0 252 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2638_19881)">
          <path
            d="M25.3734 74.7357C25.3734 80.947 20.3461 85.9743 14.1348 85.9743C7.9235 85.9743 2.89618 80.947 2.89618 74.7357C2.89618 68.5244 7.9235 63.4971 14.1348 63.4971C20.3461 63.4971 25.3734 68.5244 25.3734 74.7357Z"
            fill="#5EBCB8" />
          <path
            d="M19.8543 58.6705V70.0366H12.4226V58.5612L0 37.9419H7.88707L16.4481 52.2042L25.0273 37.9419H32.3133L19.8543 58.6705Z"
            fill="#0B3D52" />
          <path
            d="M44.7177 92.9874C44.7177 96.8671 41.5847 100 37.7049 100C33.8251 100 30.6922 96.8671 30.6922 92.9874C30.6922 89.1076 33.8251 85.9746 37.7049 85.9746C41.5847 85.9746 44.7177 89.1076 44.7177 92.9874Z"
            fill="#5EBCB8" />
          <path
            d="M42.4955 64.4445H51.1658C55.0091 64.4445 57.0856 63.2605 57.0856 60.5101C57.0856 57.7596 55.0273 56.521 51.1658 56.521H42.4955V64.4445Z"
            fill="#5EBCB8" />
          <path
            d="M55.4645 47.286C55.4645 44.7723 53.4973 43.5337 49.8725 43.5337H42.4955V51.0929H49.8725C53.4973 51.0929 55.4645 49.8178 55.4645 47.286Z"
            fill="#5EBCB8" />
          <path
            d="M124.918 47.0308C124.918 44.4989 127.395 42.641 132.441 42.641C135.574 42.641 138.761 43.2967 141.658 45.1729L144.135 40.6009C141.366 38.7977 136.667 37.7048 132.514 37.7048C127.468 37.7048 123.588 39.1802 121.348 41.6392C120.619 18.5244 101.676 0.0180664 78.3971 0.0180664C56.357 0.0180664 38.2149 16.5937 35.7195 37.9598H50.7832C58.7978 37.9598 62.9326 41.3113 62.9326 46.3022C62.9326 49.508 61.275 51.9853 58.6703 53.3696C62.2404 54.5172 64.5355 57.2676 64.5355 61.2931C64.5355 66.8487 60.1275 70.0545 51.694 70.0545H44.9727C52.8597 79.7813 64.8816 86.0108 78.3789 86.0108C91.8761 86.0108 104.59 79.4352 112.459 69.2166V53.1146C112.459 47.9962 110.109 45.6465 106.047 45.6465C101.639 45.6465 98.4517 48.3605 98.4517 54.1711V69.8177H89.2714V38.1601H98.0328V41.876C100.51 39.162 104.208 37.7048 108.506 37.7048C113.515 37.7048 117.687 39.672 119.891 43.8614C119.435 44.8997 119.18 46.0472 119.18 47.2858C119.18 59.9998 139.362 53.7886 139.362 60.9652C139.362 63.6793 137.14 65.3004 131.767 65.3004C127.614 65.3004 123.279 63.9161 120.565 61.9853L118.16 66.5572C120.82 68.6702 126.047 70.2913 131.348 70.2913C139.836 70.2913 145.137 66.6119 145.137 60.8377C145.137 48.4334 124.9 54.5718 124.9 47.049M83.4062 69.7995H74.2259V38.1419H83.4062V69.7995ZM78.816 33.7339C75.4098 33.7339 73.1147 31.4935 73.1147 28.6155C73.1147 25.7376 75.4098 23.4971 78.816 23.4971C82.2222 23.4971 84.5173 25.61 84.5173 28.4334C84.5173 31.4935 82.2222 33.7339 78.816 33.7339Z"
            fill="#5EBCB8" />
          <path
            d="M166.302 37.6867C161.603 37.6867 157.505 39.4899 154.918 43.0419V37.9781H149.381V81.5847H155.173V65.0819C157.814 68.5246 161.803 70.255 166.321 70.255C175.665 70.255 182.459 63.7522 182.459 53.9344C182.459 44.1165 175.647 37.6685 166.321 37.6685M165.829 65.2094C159.745 65.2094 155.1 60.7468 155.1 53.9526C155.1 47.1584 159.745 42.7504 165.829 42.7504C171.913 42.7504 176.612 47.2131 176.612 53.9526C176.612 60.6921 171.967 65.2094 165.829 65.2094Z"
            fill="#5EBCB8" />
          <path
            d="M190.346 24.317C188.124 24.317 186.43 26.011 186.43 28.1057C186.43 30.2005 188.106 31.8398 190.346 31.8398C192.587 31.8398 194.262 30.1458 194.262 27.9782C194.262 25.9382 192.568 24.2988 190.346 24.2988"
            fill="#5EBCB8" />
          <path d="M193.224 37.9966H187.45V69.9274H193.224V37.9966Z" fill="#5EBCB8" />
          <path
            d="M206.794 43.3514V37.9962H201.257V69.927H207.031V54.0253C207.031 47.0308 210.893 43.2421 217.086 43.2421C217.505 43.2421 217.923 43.2421 218.415 43.2967V37.6865C212.805 37.6865 208.834 39.6173 206.794 43.3514Z"
            fill="#5EBCB8" />
          <path
            d="M251.056 54.1346C251.056 44.3168 244.499 37.6865 235.282 37.6865C226.066 37.6865 219.253 44.4989 219.253 53.9525C219.253 63.406 226.12 70.273 236.412 70.273C241.712 70.273 246.12 68.4698 248.998 65.1L245.811 61.3659C243.461 63.9525 240.328 65.2275 236.594 65.2275C230.273 65.2275 225.756 61.4934 225.027 55.8832H250.929C250.984 55.3368 251.056 54.6082 251.056 54.1346ZM225.046 51.7302C225.647 46.3022 229.745 42.5681 235.282 42.5681C240.82 42.5681 244.918 46.3568 245.519 51.7302H225.046Z"
            fill="#5EBCB8" />
          <path
            d="M64.5355 61.2752C64.5355 66.8126 60.1275 70.0366 51.694 70.0366H35.1002V37.9419H50.765C58.7796 37.9419 62.9144 41.2934 62.9144 46.2843C62.9144 49.4902 61.2568 51.9674 58.6521 53.3517C62.2222 54.4993 64.5173 57.2497 64.5173 61.2752M42.4955 43.5339V51.0931H49.8725C53.4973 51.0931 55.4645 49.818 55.4645 47.2862C55.4645 44.7543 53.4973 43.5339 49.8725 43.5339H42.4955ZM57.0674 60.492C57.0674 57.7962 55.0091 56.5029 51.1475 56.5029H42.4772V64.4264H51.1475C54.9909 64.4264 57.0674 63.2424 57.0674 60.492Z"
            fill="#0B3D52" />
        </g>
        <defs>
          <clipPath id="clip0_2638_19881">
            <rect width="251.056" height="100" fill="white" />
          </clipPath>
        </defs>
      </svg>

    </div>
    <div class="right-panel">
      <TabView v-model:active-index="tabIndex">
        <TabPanel :header="$tc('register.title')" :disabled="disabledRegisterTab">
          <form>
            <p v-if="!registerConfirmation" class="m-0">
              <div class="title" v-html="$tc('register.title')" />
              <div class="intro" v-html="$tc('register.intro')" />
            <div class="register-fields-block">
              <div class="row-first-name">
                <div class="label-first-name-field" v-html="$tc('register.firstName')" />
                <div class="input-first-name-field">
                  <InputText type="text" autocomplete="off" v-model="registerModel.firstName"
                    class="field-first-name ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
                </div>
              </div>
              <div class="block-last-name">
                <div class="label-last-name-field" v-html="$tc('register.lastName')" />
                <div class="input-last-name-field">
                  <InputText type="text" autocomplete="off" v-model="registerModel.lastName"
                    class="field-last-name ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
                </div>
              </div>
            </div>
            <div class="label-email-field" v-html="$tc('register.email')" />
            <div class="input-email-field">
              <InputText type="text" autocomplete="off" v-model="registerModel.email"
                class="field-email ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
            </div>
            <!--              <div class="label-department" v-html="$tc('register.department')"></div>-->
            <!--              <div class="input-department">-->
            <!--                <InputText type="text" autocomplete="off" v-model="registerModel.department"-->
            <!--                  class="field-department ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />-->
            <!--              </div>-->
            <div class="label-password" v-html="$tc('register.password')" />
            <div class="input-password">
              <InputText type="password" autocomplete="new-password" v-model="registerModel.password"
                class="field-password ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
            </div>
            <div class="label-confirm-password" v-html="$tc('register.confirmPassword')" />
            <div class="input-confirm-password">
              <InputText type="password" autocomplete="off" v-model="registerModel.passwordConfirm"
                class="field-confirm-password ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
            </div>
            <div class="confirmation-message" v-if="resultError">
              <Message severity="error">{{ errorMessage }}</Message>
            </div>
            <div class="register-button-row">
              <!-- RegisterButton :click="registerModel.register"></RegisterButton -->
              <IciButton id="register" content="Register" @buttonClick="register()"
                btnStyle="padding: 16px 24px!important; width: auto;">
              </IciButton>
            </div>
            </p>

            <p v-if="registerConfirmation" class="m-0">

              <div class="title" v-html="$tc('register.title')" />
              <div class="intro"
                v-html="'To complete your registration, we have,<br/>sent you a confirmation code via email.</br></br>Please enter the six digit code, which you have<br/>received in your email, and click confirm.'" />

            <div class="row-confirmation-code">
              <InputText type="text" maxlength="1" v-model="confirmDigit1" autocomplete="off"
                class="field-confirmation-code ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
              <InputText type="text" maxlength="1" v-model="confirmDigit2" autocomplete="off"
                class="field-confirmation-code ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
              <InputText type="text" maxlength="1" v-model="confirmDigit3" autocomplete="off"
                class="field-confirmation-code ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
              <InputText type="text" maxlength="1" v-model="confirmDigit4" autocomplete="off"
                class="field-confirmation-code ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
              <InputText type="text" maxlength="1" v-model="confirmDigit5" autocomplete="off"
                class="field-confirmation-code ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
              <InputText type="text" maxlength="1" v-model="confirmDigit6" autocomplete="off"
                class="field-confirmation-code ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
            </div>

            <div class="confirmation-message" v-if="confirmationInCorrect">
              <Message severity="error">The code is not correct. Please correct the numbers according to the
                code in the email, and press confirm again</Message>
            </div>

            <div class="confirmation-message" v-if="confirmationExpired">
              <Message severity="error">The code is expired.Please click the resend code button, to receive another
                email
                with a new code.</Message>
            </div>

            <div class="confirmation-message" v-if="confirmationFinished">
              <Message severity="success">The code is successfully validated. You are now able to login.</Message>
            </div>

            <div v-if="!confirmationFinished" class="confirmation-button-row">
              <!-- RegisterButton :click="registerModel.register"></RegisterButton -->
              <IciButton id="confirm" v-if="!confirmationExpired" content="Confirm" @buttonClick="confirm()"
                btnStyle="width: 110px;"></IciButton>
              <IciButton id="confirm" v-if="confirmationExpired" content="Resend Code" @buttonClick="resend()"
                btnStyle="width: 140px;"></IciButton>
            </div>

            </p>

          </form>
        </TabPanel>
        <TabPanel :header="$tc('login.title')">
          <form v-if="!forgotPasswordMode && !chooseNewPasswordMode">
            <p class="m-0">
              <div class="title" v-html="$tc('login.title')" />
              <div class="label-username" v-html="$tc('login.email')" />
            <div class="input-username">
              <InputText type="text" autocomplete="off" v-model="loginModel.email"
                class="field-username ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
            </div>
            <div class="label-login-password" v-html="$tc('login.password')" />
            <div class="input-login-password">
              <InputText type="password" autocomplete="current-password" v-model="loginModel.password"
                class="field-login-password ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
            </div>
            <div class="login-button-row">
              <div class="forgot-password-link" @click="forgotPassword()">Forgot Password?</div>
              <IciButton id="login" content="Login" @buttonClick="login()"
                btnStyle="padding:  16px 24px!important; width: auto;"></IciButton>
            </div>
            </p>
          </form>

          <form v-if="forgotPasswordMode && !chooseNewPasswordMode && !confirmNewPasswordMode">
            <p class="m-0">
              <div class="title" v-html="$tc('login.forgotPasswordTitle')" />
              <div style="margin-top: 30px;" v-html="$tc('login.forgotPasswordDetails')" />
              <div class="label-username" v-html="$tc('login.email')" />
            <div class="input-username">
              <InputText type="text" autocomplete="off" v-model="loginModel.email"
                class="field-username ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
            </div>
            <div class="reset-password-button-row">
              <div style="float: left">
                <IciButton id="login" content="Cancel" @buttonClick="cancelResetPassword()"
                  btnStyle="padding:  16px 24px!important; width: auto;"></IciButton>
              </div>
              <div style="float: right">
                <IciButton id="login" content="Reset Password" @buttonClick="resetPassword()"
                  btnStyle="padding:  16px 24px!important; width: auto;"></IciButton>
              </div>
            </div>
            </p>
          </form>

          <form v-if="forgotPasswordMode && chooseNewPasswordMode">
            <p class="m-0">
              <div class="title" v-html="$tc('login.title')" />
              <div class="label-login-password" v-html="$tc('login.password')" />
            <div class="input-login-password">
              <InputText type="password" autocomplete="current-password" v-model="registerModel.password"
                class="field-login-password ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
            </div>
            <div class="label-login-password" v-html="$tc('register.confirmPassword')" />
            <div class="input-login-password">
              <InputText type="password" autocomplete="current-password" v-model="registerModel.passwordConfirm"
                class="field-login-password ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
            </div>
            <div class="login-button-row">
              <IciButton id="login" content="Set Password" @buttonClick="setPassword()"
                btnStyle="padding:  16px 24px!important; width: auto;"></IciButton>
            </div>
            </p>
          </form>

          <form v-if="forgotPasswordMode && confirmNewPasswordMode">
            <p class="m-0">
              <div class="title" v-html="$tc('login.title')" />

            <div class="row-confirmation-code">
              <InputText type="text" maxlength="1" v-model="confirmDigit1" autocomplete="off"
                class="field-confirmation-code ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
              <InputText type="text" maxlength="1" v-model="confirmDigit2" autocomplete="off"
                class="field-confirmation-code ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
              <InputText type="text" maxlength="1" v-model="confirmDigit3" autocomplete="off"
                class="field-confirmation-code ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
              <InputText type="text" maxlength="1" v-model="confirmDigit4" autocomplete="off"
                class="field-confirmation-code ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
              <InputText type="text" maxlength="1" v-model="confirmDigit5" autocomplete="off"
                class="field-confirmation-code ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
              <InputText type="text" maxlength="1" v-model="confirmDigit6" autocomplete="off"
                class="field-confirmation-code ps-1 py-1 ring-0 border-2 border-[#fff] focus:border-[#5ebcb8]" />
            </div>

            <div class="confirmation-message" v-if="passwordResetConfirmationCodeInValid">
              <Message severity="error">The code is invalid. Please check the code in the email.</Message>
            </div>

            <div class="confirmation-message" v-if="passwordResetSuccessful">
              <Message severity="success">Your password is successful changed.<br />You can now login using your new
                password.</Message>
            </div>

            <div class="login-button-row">
              <IciButton id="login" content="Confirm New Password" @buttonClick="confirmNewPassword()"
                btnStyle="padding:  16px 24px!important; width: auto;"></IciButton>
            </div>
            </p>
          </form>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import IciButton from "@/components/IciButton.vue";
import { AuthService } from "@/service/AuthService";
import { RegisterModel } from "@/data/RegisterModel";
import { useRoute } from "vue-router";
import axios from "axios";
import { Application } from "@/data/Application";
import { useToast } from 'primevue/usetoast';

export default defineComponent({
  name: "LoginView",
  components: { IciButton },
  computed: {
    AuthService() {
      return AuthService
    }
  },
  methods: {
    forgotPassword() {
      this.disabledRegisterTab = true;
      this.forgotPasswordMode = true;
      this.chooseNewPasswordMode = false;
      this.confirmNewPasswordMode = false;
      this.loginModel.password = "";
    },
    resetPassword() {
      axios.post(Application.instance.apiEndPoint + '/auth/user/auth/reset-password', this.loginModel)
        .then(response => {
          this.disabledRegisterTab = false;
          this.forgotPasswordMode = false;
          this.chooseNewPasswordMode = false;
          this.confirmNewPasswordMode = false;
          this.loginModel.password = "";
        })
        .catch(err => {
          console.log(err);
        });
    },
    cancelResetPassword() {
      this.disabledRegisterTab = false;
      this.forgotPasswordMode = false;
      this.chooseNewPasswordMode = false;
      this.confirmNewPasswordMode = false;
    },
    setPassword() {
      axios.post(Application.instance.apiEndPoint + '/auth/user/auth/set-new-password', this.registerModel)
        .then(response => {
          this.disabledRegisterTab = true;
          this.forgotPasswordMode = true;
          this.chooseNewPasswordMode = false;
          this.confirmNewPasswordMode = true;
        })
        .catch(err => {
          console.log(err);
        });
    },
    confirmNewPassword() {
      let confirmationCode = this.confirmDigit1 + this.confirmDigit2 + this.confirmDigit3 + this.confirmDigit4 + this.confirmDigit5 + this.confirmDigit6;
      axios.post(Application.instance.apiEndPoint + '/auth/user/auth/confirm-new-password', { email: this.registerModel.email, code: confirmationCode })
        .then(response => {
          this.disabledRegisterTab = true;
          this.forgotPasswordMode = true;
          this.chooseNewPasswordMode = false;
          if (response.data.codeIsValid) {
            this.passwordResetConfirmationCodeInValid = false;
            this.passwordResetSuccessful = true;
            setTimeout(() => {
              this.disabledRegisterTab = false;
              this.forgotPasswordMode = false;
              this.chooseNewPasswordMode = false;
              this.confirmNewPasswordMode = false;
              this.loginModel.password = "";
            }, 2000);
          } else {
            this.confirmNewPasswordMode = true;
            this.passwordResetConfirmationCodeInValid = true;
            this.passwordResetSuccessful = false;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    login() {
      axios.post(Application.instance.apiEndPoint + '/auth/user/auth', this.loginModel).then(response => {
        AuthService.logOut();
        if (response.data.user.resetPasswordProvided) {
          this.registerModel.email = response.data.user.email;
          this.disabledRegisterTab = true;
          this.forgotPasswordMode = true;
          this.chooseNewPasswordMode = true;
          this.confirmNewPasswordMode = false;
        } else {
          if (!response.data.user && !response.data.token) {
            this.toast.add({ 
              severity: 'error', 
              summary: 'Login Failed', 
              detail: 'Invalid username or password', 
              life: 3000 
            });
            return;
          }

          this.disabledRegisterTab = false;
          this.forgotPasswordMode = false;
          this.chooseNewPasswordMode = false;
          this.confirmNewPasswordMode = false;
          AuthService.login();
          this.$nextTick(() => {
            this.$router.push('/');
          })
        }
      })
      .catch(err => {
        this.toast.add({ 
          severity: 'error', 
          summary: 'Login Failed', 
          detail: 'An error occurred while trying to log in', 
          life: 3000 
        });
      });
    },
    register: function () {
      axios.post(Application.instance.apiEndPoint + '/auth/mail/invitation/' + this.code + '/register', this.registerModel)
        .then(response => {
          console.log(response.data)
          if (response.data.proceedType == "CONFIRMATION_NEEDED") {
            this.registerConfirmation = true;
            this.resultError = false;
            this.errorMessage = "";
          } else if (response.data.proceedType == "EMAIL_EXIST") {
            this.registerConfirmation = false;
            this.resultError = true;
            this.errorMessage = "Email already exist.";
            console.log("Email already exist")
          } else if (response.data.proceedType == "INVITATION_NOT_FOUND") {
            this.registerConfirmation = false;
            this.resultError = true;
            this.errorMessage = "No open invitation found.";
            console.log("No open invitation found")
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    confirm: function () {
      let confirmationCode = this.confirmDigit1 + this.confirmDigit2 + this.confirmDigit3 + this.confirmDigit4 + this.confirmDigit5 + this.confirmDigit6;
      axios.post(Application.instance.apiEndPoint + '/auth/mail/invitation/' + this.code + '/register/confirm', { 'code': confirmationCode })
        .then(response => {
          console.log(response.data)
          if (response.data.proceedType == "CONFIRMATION_CORRECT") {
            this.confirmationFinished = true;
            this.confirmationInCorrect = false;
            this.confirmationExpired = false;
            this.resultError = false;
            this.errorMessage = "";
            setTimeout(() => { location.href = "/" }, 3000);
          }
          if (response.data.proceedType == "CONFIRMATION_INCORRECT") {
            this.confirmationFinished = false;
            this.confirmationInCorrect = true;
            this.confirmationExpired = false;
            this.resultError = false;
            this.errorMessage = "";
          }
          if (response.data.proceedType == "CONFIRMATION_EXPIRED") {
            this.confirmationFinished = false;
            this.confirmationInCorrect = false;
            this.confirmationExpired = true;
            this.resultError = false;
            this.errorMessage = "";
          }

        })
        .catch(err => {
          console.log(err);
        });
    },
    resend: function () {
      this.confirmDigit1 = "";
      this.confirmDigit2 = "";
      this.confirmDigit3 = "";
      this.confirmDigit4 = "";
      this.confirmDigit5 = "";
      this.confirmDigit6 = "";
      axios.get(Application.instance.apiEndPoint + '/auth/mail/invitation/' + this.code + "/register/resend")
        .then(response => {
          this.registerConfirmation = true;
          this.confirmationFinished = false;
          this.confirmationInCorrect = false;
          this.confirmationExpired = false;
          this.resultError = false;
          this.errorMessage = "";
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  setup(props) {
    const toast = useToast();
    let disabledRegisterTab = ref(false);
    let disabledLoginTab = ref(false);
    let forgotPasswordMode = ref(false);
    let chooseNewPasswordMode = ref(false);
    let confirmNewPasswordMode = ref(false);
    let passwordResetConfirmationCodeInValid = ref(false);
    let passwordResetSuccessful = ref(false);
    let tabIndex = ref(1);
    let registerModel = ref(new RegisterModel());
    let loginModel = ref(AuthService.getModel());
    let code = ref("");
    let registerConfirmation = ref(false);
    let confirmationFinished = ref(false);
    let confirmationInCorrect = ref(false);
    let confirmationExpired = ref(false);
    let resultError = ref(false);
    let errorMessage = ref("");
    let confirmationMessage = ref("");
    let confirmDigit1 = ref("");
    let confirmDigit2 = ref("");
    let confirmDigit3 = ref("");
    let confirmDigit4 = ref("");
    let confirmDigit5 = ref("");
    let confirmDigit6 = ref("");

    let path: string[] = location.href.split("/#");
    console.log(path);
    if (path.length > 1) {
      code.value = path[path.length - 1]
      if (isValidUUID(code.value)) {
        axios.get(Application.instance.apiEndPoint + '/auth/mail/invitation/' + code.value)
          .then(response => {
            if (response.data.proceedType == "REGISTER") {
              tabIndex.value = 0;
              registerConfirmation.value = false;
              registerModel.value.email = response.data.email;
            } else if (response.data.proceedType == "CONFIRMATION_NEEDED") {
              tabIndex.value = 0;
              registerConfirmation.value = true;
            } else {
              tabIndex.value = 1;
              loginModel.value.email = response.data.email;
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
    return {
      tabIndex, registerModel, loginModel, code, registerConfirmation,
      confirmDigit1, confirmDigit2, confirmDigit3, confirmDigit4, confirmDigit5, confirmDigit6,
      confirmationFinished, confirmationInCorrect, confirmationExpired, resultError, errorMessage,
      disabledRegisterTab, disabledLoginTab, forgotPasswordMode, chooseNewPasswordMode, confirmNewPasswordMode,
      passwordResetConfirmationCodeInValid, passwordResetSuccessful, toast
    };
  },
});

function isValidUUID(uuid: string) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}
</script>

<style>
.forgot-password-link {
  display: inline-block;
  width: 200px;
  text-align: left;
  line-height: 50px;
  cursor: pointer;
}

.forgot-password-link:hover {
  font-weight: bold;
}

#app {
  height: 100vh;
  display: flex;
  width: 100%;
}

#app>div {
  width: 100%;
}

input {
  width: 100% !important;
  border-radius: 10px;
}

.master-panel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center
}

.left-panel {
  display: flex;
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
}

.right-panel {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
  background-color: #ffffff;
}

.right-inner-relative {
  position: relative
}

.title {
  font-family: "Public Sans";
  font-size: 32px;
  font-weight: bold;
  padding-top: 32px;
}

.intro {
  font-family: "Public Sans";
  font-size: 16px;
  text-align: center;
  margin: 40px 0 32px 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.intro p {
  margin: 0;
}

.register-fields-block {
  font-size: 16px;
  text-align: left;
  margin-top: 32px;
  display: flex;
  gap: 16px;
}

.register-fields-block>div {
  width: 50%
}

.field-confirmation-code {
  text-align: center !important;
  width: 40px !important;
  margin-right: 1px;
}

.confirmation-button-row {
  margin-top: 50px;
  text-align: center;
  padding-bottom: 30px;
}

.confirmation-message-row {
  margin-top: 50px;
  text-align: center;
  padding-right: 40px;
  padding-bottom: 30px;
}

.label-first-name-field {
  font-family: "Public Sans";
  font-weight: bold;
  font-size: 16px;
  text-align: left;
}

.input-first-name-field {
  margin-top: 3px;
  text-align: left;
}

.label-last-name-field {
  font-family: "Public Sans";
  font-weight: bold;
  font-size: 16px;
  text-align: left;
}

.input-last-name-field {
  margin-top: 3px;
  text-align: left;
}

.label-email-field {
  font-family: "Public Sans";
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-top: 15px;
}

.input-email-field {
  margin-top: 3px;
}

.label-department {
  font-family: "Public Sans";
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-top: 15px;
}

.input-department {
  margin-top: 3px;
  text-align: left;
}

.label-password {
  font-family: "Public Sans";
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-top: 15px;
}

.input-password {
  margin-top: 3px;
  text-align: left;
}

.label-confirm-password {
  font-family: "Public Sans";
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-top: 15px;
}

.input-confirm-password {
  margin-top: 3px;
  text-align: left;
}

.register-button-row {
  margin-top: 20px;
  text-align: right;
}

.label-username {
  font-family: "Public Sans";
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-top: 32px;
}

.input-username {
  margin-top: 3px;
}

.label-login-password {
  font-family: "Public Sans";
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-top: 24px;
}

.input-login-password {
  margin-top: 3px;
}

.login-button-row {
  text-align: right;
  margin-top: 24px;
}

.reset-password-button-row {
  margin-top: 24px;
}

.p-tabview-panels {
  background-color: #F3F4F8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 40px 40px 40px;
  max-height: 80%;
  overflow-y: auto;
}

.p-tabview-nav {
  background-color: #F3F4F8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  justify-content: center;
  display: flex;
  padding: 40px 40px 0 40px;
}

.p-tabview-nav-link {
  background-color: #F3F4F8;
  padding: 12px 24px;
}

.p-tabview-header {
  width: 50%
}

.p-tabview-nav-link {
  justify-content: center;
}

.p-tabview {
  width: 100% !important;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.p-tabview .p-tabview-nav {
  border-width: 0 0 0 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #F3F4F8;
  color: #6b7280;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #0D3D52 !important;
  border-bottom-width: 5px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.p-tabview .p-tabview-nav .p-tabview-nav-link {
  border-color: #F3F4F8 !important;
  border-bottom-width: 5px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.tabbed-view {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
