import {createApp} from 'vue'
import 'primevue/resources/themes/lara-light-indigo/theme.css'; // Or another theme of your choice
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';

import App from './App.vue'
import PrimeVue from 'primevue/config';
import router from './router'
import store from './store'
import Steps from 'primevue/steps';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Rating from 'primevue/rating';
import Button from 'primevue/button';
import Avatar from 'primevue/avatar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Card from 'primevue/card';
import ProgressBar from 'primevue/progressbar';
import MegaMenu from 'primevue/megamenu';
import Menubar from 'primevue/menubar';
import Textarea from 'primevue/textarea';
import Knob from 'primevue/knob';
import Slider from 'primevue/slider';
import RadioButton from 'primevue/radiobutton';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Message from 'primevue/message';
import Paginator from 'primevue/paginator';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row';
import Chips from 'primevue/chips';
import Chip from 'primevue/chip';



import Badge from 'primevue/badge';
// @ts-ignore
import en from './locales/en.json';
// @ts-ignore
import fr from './locales/fr.json';
import {createI18n} from 'vue-i18n'
import BadgeDirective from 'primevue/badgedirective';
import './assets/fonts/fonts.css';


const i18n = createI18n({
    legacy: true,           // so that VueI18n still works with Options API
    globalInjection: true,
    locale: 'en',
    fallbackLocale: 'en',
    warnHtmlInMessage: 'off',
    messages: {
        en,
        fr
    }
});


export default i18n;

let app =
    createApp(App)
        .use(store)
        .use(router)
        .use(i18n)
        .use(PrimeVue)
        .component("Steps", Steps)
        .component("Stepper", Stepper)
        .component("StepperPanel", StepperPanel)
        .component("InputText", InputText)
        .component("Dropdown", Dropdown)
        .component("InputNumber", InputNumber)
        .component("Rating", Rating)
        .component("Button", Button)
        .component("Avatar", Avatar)
        .component("TabView", TabView)
        .component("TabPanel", TabPanel)
        .component("Card", Card)
        .component("ProgressBar", ProgressBar)
        .component("MegaMenu", MegaMenu)
        .component("Menubar", Menubar)
        .component("Textarea", Textarea)
        .component("Knob", Knob)
        .component("Slider", Slider)
        .component("Badge", Badge)
        .component("Chip", Chip)
        .component("Chips", Chips)
        .component("RadioButton",RadioButton)
        .component("DataTable", DataTable)
        .component("Column", Column)
        .component("Calendar", Calendar)
        .component("Checkbox", Checkbox)
        .component("Message", Message)
        .component("Paginator", Paginator)
        .directive('Badge', BadgeDirective)

app.use(ToastService);
app.component('Toast', Toast);
app.mount('#app')
